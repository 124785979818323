define("ember-pikaday/templates/pikaday-inputless", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H8FHtpdR",
    "block": "{\"symbols\":[],\"statements\":[[10,\"input\"],[14,0,\"ember-pikaday-input\"],[14,4,\"hidden\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"ember-pikaday-container\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-pikaday/templates/pikaday-inputless.hbs"
    }
  });

  _exports.default = _default;
});