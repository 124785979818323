define("ember-inputmask/components/one-way-phone-mask", ["exports", "ember-inputmask/components/one-way-input-mask"], function (_exports, _oneWayInputMask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class OneWayPhoneMask
   */
  var _default = _oneWayInputMask.default.extend({
    NON_ATTRIBUTE_BOUND_PROPS: _oneWayInputMask.DEFAULT_NON_BOUND_PROPS.concat('extensions'),

    /**
     * Whether or not to include extension in the mask
     *
     * @argument extensions
     * @type Boolean
     */
    extensions: false,

    /**
     * @computed mask
     * @override
     */
    mask: Ember.computed('extensions', {
      get: function get() {
        if (this.extensions) {
          return '(999) 999-9999[ x 9{1,4}]';
        }

        return '(999) 999-9999';
      },
      set: function set(_key, value) {
        return value;
      }
    })
  });

  _exports.default = _default;
});