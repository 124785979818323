define("ember-light-table/components/lt-column-resizer", ["exports", "ember-light-table/utils/closest", "ember-light-table/templates/components/lt-column-resizer"], function (_exports, _closest, _ltColumnResizer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TOP_LEVEL_CLASS = '.ember-light-table';

  var _default = Ember.Component.extend({
    layout: _ltColumnResizer.default,
    classNameBindings: [':lt-column-resizer', 'isResizing'],
    column: null,
    resizeOnDrag: false,
    isResizing: false,
    startWidth: null,
    startX: null,
    colElement: function colElement() {
      return this.element.parentNode;
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.__mouseMove = this._mouseMove.bind(this);
      this.__mouseUp = this._mouseUp.bind(this);
      document.addEventListener('mousemove', this.__mouseMove);
      document.addEventListener('mouseup', this.__mouseUp);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      document.removeEventListener('mousemove', this.__mouseMove);
      document.removeEventListener('mouseip', this.__mouseUp);
    },
    click: function click(e) {
      /*
        Prevent click events from propagating (i.e. onColumnClick)
       */
      e.preventDefault();
      e.stopPropagation();
    },
    mouseDown: function mouseDown(e) {
      var column = this.colElement();
      e.preventDefault();
      e.stopPropagation();
      this.setProperties({
        isResizing: true,
        startWidth: column.offsetWidth,
        startX: e.pageX
      });
      var topLevel = (0, _closest.default)(this.element, TOP_LEVEL_CLASS);
      topLevel.classList.add('is-resizing');
    },
    _mouseUp: function _mouseUp(e) {
      if (this.isResizing) {
        e.preventDefault();
        e.stopPropagation();
        var column = this.colElement();
        var width = "".concat(column.offsetWidth, "px");
        this.set('isResizing', false);
        this.set('column.width', width);
        var topLevel = (0, _closest.default)(this.element, TOP_LEVEL_CLASS);
        topLevel.classList.remove('is-resizing');
        this.onColumnResized(width);
      }
    },
    _mouseMove: function _mouseMove(e) {
      if (this.isResizing) {
        e.preventDefault();
        e.stopPropagation();
        var resizeOnDrag = this.resizeOnDrag;
        var minResizeWidth = this.get('column.minResizeWidth');

        var _this$getProperties = this.getProperties(['startX', 'startWidth']),
            startX = _this$getProperties.startX,
            startWidth = _this$getProperties.startWidth;

        var width = "".concat(Math.max(startWidth + (e.pageX - startX), minResizeWidth), "px");
        var column = this.colElement();
        var index = this.get('table.visibleColumns').indexOf(this.column) + 1;
        var table = (0, _closest.default)(this.element, TOP_LEVEL_CLASS);
        column.style.width = width;
        var headerScaffoldingCell = table.querySelector("thead td.lt-scaffolding:nth-child(".concat(index, ")"));

        if (headerScaffoldingCell) {
          headerScaffoldingCell.style.width = width;
        }

        var footerScaffoldingCell = table.querySelector("tfoot td.lt-scaffolding:nth-child(".concat(index, ")"));

        if (footerScaffoldingCell) {
          footerScaffoldingCell.style.width = width;
        }

        if (resizeOnDrag) {
          var cols = table.querySelectorAll("tbody td:nth-child(".concat(index, ")"));
          cols.forEach(function (col) {
            col.style.width = width;
          });
        }
      }
    },
    // No-op for closure actions
    onColumnResized: function onColumnResized() {}
  });

  _exports.default = _default;
});