define("ember-print-this/templates/components/print-this", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8BtJxM6e",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,0],[[32,0],\"doPrint\"],null]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "ember-print-this/templates/components/print-this.hbs"
    }
  });

  _exports.default = _default;
});