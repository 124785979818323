define('ember-text-mask/components/masked-input', ['exports', 'ember-text-mask/createTextMaskInputElement'], function (exports, _createTextMaskInputElement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function _config() {
    var _Ember;

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return (_Ember = Ember).computed.apply(_Ember, args.concat([function () {
      var _Ember2;

      return (_Ember2 = Ember).getProperties.apply(_Ember2, [this].concat(args));
    }]));
  }

  var mask = [];

  /*
  
    ## MaskedInputComponent
  
    Add the following markup to your template to render a masked input component.
  
    ```hbs
    {{masked-input mask=mask}}
    ```
  
    In the template's controller, specify a `mask`.
  
    ```js
    import Ember from 'ember';
  
    export default Ember.Controller.extend({
  
      mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  
    });
    ```
  */
  exports.default = Ember.TextField.extend({

    mask: mask,

    /*
      ## config {Object}
       This is a computed property and will re-compute when any of the dependent properties
      update.  By default it will read the properties off the component root, you
      can pass in attrbutes to the component through the template.
       ```hbs
      {{masked-input
        mask=customMask
        guide=true}}
      ```
    */
    config: _config('mask', 'guide', 'placeholderChar', 'keepCharPositions', 'pipe', 'showMask'),

    /*
      ## textMaskInputElement {Object}
       `textMaskInputElement` is the object that is returned from calling the
      `createTextMaskInputElement`. method.
       This is a computed property and will re-compute whenever the `config` property
      changes.
    */
    textMaskInputElement: Ember.computed('config', function () {
      var config = Ember.get(this, 'config');
      config.inputElement = this.element;
      return this.createTextMaskInputElement(config);
    }),

    createTextMaskInputElement: _createTextMaskInputElement.default,

    update: function update() {
      var _get;

      (_get = this.get('textMaskInputElement')).update.apply(_get, arguments);
    },


    _input: Ember.on('input', 'didRender', function () {
      this.update();
    })
  });
});